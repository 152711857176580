/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {readlink} from 'fs'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  loading: boolean
  data: {
    totalFlightsData: FlightsBy90Days
    aircraftData: AircraftData[]
    airportsData: AirportData[]
    rank: number
    rankTotal: number
  }
}

type FlightsBy90Days = FlightData[]

type FlightData = {
  date: string
  close: number
}

type AircraftData = {
  type: string
  total_flights: number
}

type AirportData = {
  icao: string
  total_flights: number
}

type topData = {name: string; val: number} | null

const TotalFlights: React.FC<Props> = ({className, chartColor, chartHeight, loading, data}) => {
  const [topAircraft, setTopAircraft] = useState<topData>(null)
  const [topAirport, setTopAirport] = useState<topData>(null)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const convertChartData = ({totalFlightsData}: Props['data']): [number[], string[]] => {
    // const categories = ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
    // const chartData = [30, 30, 60, 25, 25, 40]
    const chartData: number[] = []
    const categories: string[] = []
    if (!totalFlightsData?.length) {
      return [chartData, categories]
    }

    totalFlightsData.forEach((flightData: FlightData) => {
      const date = new Date(flightData.date)
      const month = date.toLocaleString('default', {month: 'short'})
      const year = date.getFullYear()
      const monthYear = `${month} ${year}`
      const flights = flightData.close

      if (categories.includes(monthYear)) {
        const index = categories.indexOf(monthYear)
        if (chartData[index] < flights) {
          chartData[index] = flights
        }
      } else {
        categories.push(monthYear)
        chartData.push(flights)
      }
    })

    if (chartData.length < 3) {
      const diff = 3 - chartData.length
      for (let i = 0; i < diff; i++) {
        chartData.push(0)
      }
    }

    if (categories.length < 3) {
      const diff = 3 - categories.length
      for (let i = 0; i < diff; i++) {
        categories.push('')
      }
    }

    chartData.reverse()
    categories.reverse()
    return [chartData, categories]
  }

  const getTopAircraft = ({aircraftData}: Props['data']): topData => {
    if (!aircraftData?.length) {
      return null
    }
    let topAircraft = ''
    let topFlights = 0

    aircraftData.forEach((aircraft: AircraftData) => {
      if (aircraft.total_flights > topFlights) {
        topAircraft = aircraft.type
        topFlights = aircraft.total_flights
      }
    })

    return topAircraft !== '' ? {name: topAircraft, val: topFlights} : null
  }

  const getTopAirport = ({airportsData}: Props['data']): topData => {
    if (!airportsData?.length) {
      return null
    }
    let topAirport = ''
    let topFlights = 0

    airportsData.forEach((airport: AirportData) => {
      if (airport.total_flights > topFlights) {
        topAirport = airport.icao
        topFlights = airport.total_flights
      }
    })

    return topAirport !== '' ? {name: topAirport, val: topFlights} : null
  }

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const [chartData, categories] = convertChartData(data)

    // Set top aircraft and airport
    const topAircraft = getTopAircraft(data)
    const topAirport = getTopAirport(data)
    setTopAircraft(topAircraft)
    setTopAirport(topAirport)

    const chart1 = new ApexCharts(
      chartRef.current,
      chart1Options(chartColor, chartHeight, chartData, categories)
    )
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()
    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data])

  let rankText = ''
  const percentage = Math.round((data.rank / data.rankTotal) * 100) + '%'
  const top = data.rank <= data.rankTotal / 5
  const bottom = data.rank >= data.rankTotal - data.rankTotal / 5
  if (top) {
    rankText = 'Top ' + percentage
  } else if (bottom) {
    rankText = 'Bottom ' + percentage
  }

  if (loading) {
    return (
      <div className={`card ${className}`}>
        {/* begin::Beader */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Total Flights</span>

            <span className='text-muted fw-semibold fs-7'>Your Total Flights</span>
          </h3>

          <div className='card-toolbar'>
            {/* begin::Menu */}
            {/* <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <Dropdown1 /> */}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <span className='px-10 loading-placeholder'>Loading Flights...</span>
        <div className='card-body d-flex flex-column'>
          <div className='flex-grow-1 pb-5'>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <span className='text-muted fw-bold fs-7'>Total Flights</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (data.totalFlightsData.length === 0) {
    return (
      <div className={`card ${className}`}>
        {/* begin::Beader */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Total Flights</span>

            <span className='text-muted fw-semibold fs-7'>Your Total Flights</span>
          </h3>

          <div className='card-toolbar'>
            {/* begin::Menu */}
            {/* <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <Dropdown1 /> */}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <span className='px-10 loading-placeholder'>No Flights yet ✈️</span>
      </div>
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Total Flights</span>

          <span className='text-muted fw-semibold fs-7'>Your Total Flights</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button> */}
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
        {/* end::Chart */}

        {/* begin::Items */}
        <div className='mt-5'>
          {/* begin::Item */}
          {topAircraft && (
            <div className='d-flex flex-stack mb-5'>
              {/* begin::Section */}
              <div className='d-flex align-items-center me-2'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light'>
                    {/* <img
                      src={toAbsoluteUrl('/media/icons/duotune/aviation/fill/Airplane.svg')}
                      alt=''
                      className='h-50 svg-icon-white'
                    /> */}
                    <KTSVG
                      path={'/media/icons/duotune/aviation/fill/Airplane.svg' || ''}
                      className={'svg-icon-white svg-icon-1 ms-n1'}
                    />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                    Top Aircraft
                  </a>
                  <div className='fs-7 text-muted fw-semibold mt-1'>{topAircraft.name}</div>
                </div>
                {/* end::Title */}
              </div>
              {/* end::Section */}

              {/* begin::Label */}
              <div className='badge badge-light fw-semibold py-4 px-3'>
                {topAircraft.val} Flights
              </div>
              {/* end::Label */}
            </div>
          )}
          {/* end::Item */}

          {/* begin::Item */}
          {topAirport && (
            <div className='d-flex flex-stack mb-5'>
              {/* begin::Section */}
              <div className='d-flex align-items-center me-2'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light'>
                    <KTSVG
                      path={'/media/icons/duotune/aviation/fill/Airport.svg' || ''}
                      className={'svg-icon-white svg-icon-1 ms-n1'}
                    />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                    Top Airport
                  </a>
                  <div className='fs-7 text-muted fw-semibold mt-1'>{topAirport.name}</div>
                </div>
                {/* end::Title */}
              </div>
              {/* end::Section */}

              {/* begin::Label */}
              <div className='badge badge-light fw-semibold py-4 px-3'>
                {topAirport.val} Flights
              </div>
              {/* end::Label */}
            </div>
          )}
          {/* end::Item */}

          {/* begin::Item */}
          <div className='d-flex flex-stack'>
            {/* begin::Section */}
            <div className='d-flex align-items-center me-2'>
              {/* begin::Symbol */}
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light'>
                  <KTSVG
                    path={'/media/icons/duotune/aviation/fill/Pilot.svg' || ''}
                    className={'svg-icon-white svg-icon-1 ms-n1'}
                  />
                </div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div className='py-1'>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                  Airline Rank
                </a>
                <div className='fs-7 text-muted fw-semibold mt-1'>
                  {rankText !== '' && rankText} by Flights
                </div>
              </div>
              {/* end::Title */}
            </div>
            {/* end::Section */}

            {/* begin::Label */}
            <div className='badge badge-light fw-semibold py-4 px-3'># {data.rank}</div>
            {/* end::Label */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chart1Options = (
  chartColor: string,
  chartHeight: string,
  data: number[],
  categories: string[]
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor) as string
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')

  if (data.length !== categories.length) {
    throw new Error('data and categories must have the same length')
  }

  // Get maximum value from data
  const max = Math.max(...data)
  return {
    series: [
      {
        name: 'Total Flights',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    fill1: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: max + max * 0.2,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' Flights'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      // @ts-ignore
      strokeColor: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {TotalFlights}
