import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {BookedFlightsTable} from '../../../_metronic/partials/widgets/tables/BookedFlightsTable'
import {JumpseatWidget} from './JumpseatWidget'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BidsPage = () => {
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <BookedFlightsTable className='' />
      </div>
    </>
  )
}

const JumpseatWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Jumpseat'}</PageTitle>
      <JumpseatWidget />
    </>
  )
}

export {JumpseatWrapper}
