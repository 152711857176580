import {useEffect, useState} from 'react'

type Props = {
  id: string
  url: string
  style: any
}

export const DocView: React.FC<Props> = ({id, url, style}) => {
  const [render, setRender] = useState(false)
  const url2 = 'https://docs.google.com/gview?url=' + url + '&embedded=true'

  useEffect(() => {
    window.scrollTo(0, 0)
    // If iframe is not loaded, reload it

    const tryRender = () => {
      if (!render) {
        try {
          // reload component
          console.log('try')
          // @ts-ignore
          document.getElementById(id)?.contentWindow.location.reload()
        } catch {
          clearInterval(interval)
          setRender(true)
          return
        }
        // @ts-ignore

        // set src to null and back to url
        // to force iframe to reload
        // @ts-ignore
        // ;(document.getElementById(id) as any)?.src = (document.getElementById(id) as any)?.src
        const urlTry = document.getElementById(id)?.src
        // @ts-ignore
        document.getElementById(id).src = ''
        // @ts-ignore
        document.getElementById(id).src = urlTry
      }
    }
    const interval = setInterval(tryRender, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      {!render && <>Fetching Brief From Dispatch...</>}
      <iframe id={id} style={style} src={url2} frameBorder='0'></iframe>
    </>
  )
}
