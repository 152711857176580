/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  data: UserData[]
  loading: boolean
  title?: string
}

type UserData = {
  public_id: string
  username: string
  flights: number
  ranks: any[]
  active: boolean
  avatar_url: string
  bases: {icao: string}[]
}

export const UserRow: React.FC<{user: UserData; index: number}> = ({user, index}) => {
  return (
    <tr key={'roster-' + user.public_id}>
      <td>
        <div className='symbol symbol-50px me-2'>
          <div className='symbol symbol-45px me-5'>
            <img
              src={user?.avatar_url || 'https://rotate-group.b-cdn.net/user-images/default.jpg'}
              alt=''
            />
          </div>
        </div>
      </td>
      <td>
        <a
          href={`/profile/${user?.username}`}
          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
        >
          {user?.username}
        </a>
        <span className='text-muted fw-semibold d-block fs-7'>
          {[user.bases[0]?.icao, user.ranks.length ? user.ranks[0]?.rank : 'New Hire']
            .filter(Boolean)
            .join(' - ')}
        </span>
      </td>
      <td className='text-end'>
        <span className='text-muted fw-semibold d-block fs-8'>Flights</span>
        <span className='text-dark fw-bold d-block fs-7'>{user.flights}</span>
      </td>
      <td className='text-end'>
        <span
          className={
            user.active
              ? 'badge badge-light-success fs-7 fw-bold'
              : 'badge badge-light-warning fs-7 fw-bold'
          }
        >
          {user.active ? 'Active' : 'Inactive'}
        </span>
      </td>
      <td className='text-end'>
        <a href='#' className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
          <i className='bi bi-three-dots fs-5'></i>
        </a>
      </td>
    </tr>
  )
}

const RosterTable: React.FC<Props> = ({className, data, loading, title = 'Pilot Roster'}) => {
  const [limit, setLimit] = useState(250)
  const incLimit = 250

  if (loading) {
    return <></>
  }

  if (data.length) {
    // sort by flights
    data.sort((a, b) => (a.flights < b.flights ? 1 : -1))
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {/* Round data.length to the nearest ten */}
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Over {data.length ? data.length - (data.length % 10) || data.length : 0} Pilots
          </span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_1'
              >
                All
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_2'
              >
                Active
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_3'
              >
                Inactive
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_7_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.length && data.slice(0, limit).map((user, index) => UserRow({user, index}))}
                  {
                    // if length is greater than n show load more button. and center btton
                    data?.length > limit && (
                      <tr>
                        <td colSpan={7} className='text-center'>
                          <button
                            onClick={() => {
                              setLimit(limit + incLimit)
                            }}
                            className='btn btn-primary'
                          >
                            Load More
                          </button>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_7_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.length &&
                    data
                      .filter((u) => u.active)
                      .slice(0, limit)
                      .map((user, index) => UserRow({user, index}))}
                  {
                    // if length is greater than n show load more button. and center btton
                    data?.length > limit && (
                      <tr>
                        <td colSpan={7} className='text-center'>
                          <button
                            onClick={() => {
                              setLimit(limit + incLimit)
                            }}
                            className='btn btn-primary'
                          >
                            Load More
                          </button>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_7_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.length &&
                    data
                      .filter((u) => !u.active)
                      .slice(0, limit)
                      .map((user, index) => UserRow({user, index}))}
                  {
                    // if length is greater than n show load more button. and center btton
                    data?.length > limit && (
                      <tr>
                        <td colSpan={7} className='text-center'>
                          <button
                            onClick={() => {
                              setLimit(limit + incLimit)
                            }}
                            className='btn btn-primary'
                          >
                            Load More
                          </button>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {RosterTable}
