import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getAirports, getFlightById, getLiveFlightUpdates} from '../../../middleware'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {BookedFlightsTable} from '../../../_metronic/partials/widgets/tables/BookedFlightsTable'
import {LiveFlightsTable} from './LiveFlightTable'
import {LiveMap} from './LiveMap'

const cacheTime = 5 * 60000
const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LiveMapPage = () => {
  const [markers, setMarkers] = useState([])
  const [activeFlights, setActiveFlights] = useState([])
  const [flightInfo, setFlightInfo] = useState<any>({})

  const {
    data: airportsData,
    isLoading: airportsLoading,
    error: airportsError,
  } = useQuery('airports', getAirports)

  useEffect(() => {
    const getData = async () => {
      const res = await getLiveFlightUpdates()
      const newMarkers: any = []
      if (!res) return
      if (res.length > 0) {
        res.forEach((data: any) => {
          const marker = JSON.parse(data.data)
          const prevMarker: any = markers.find((m: any) => m.id === data.id)
          const prevCoords = [
            prevMarker?.position_report?.latitude,
            prevMarker?.position_report?.longitude,
          ]
          newMarkers.push({
            id: data.id,
            position_report: marker,
            prevCoords: prevCoords,
          })
        })
      }

      setActiveFlights(res)
      setMarkers(newMarkers)
    }

    const getMapData = setInterval(() => getData(), 250)

    return () => {
      clearInterval(getMapData)
    }
  }, [markers])

  useEffect(() => {
    activeFlights.map(async (data: any) => {
      const markerData = JSON.parse(data.data)
      const flightId = markerData.flightId
      if (!flightId) return
      // get flight data if it doesn't exist
      if (flightId in flightInfo === false) {
        const res = await getFlightById(flightId)
        if (!res) return
        setFlightInfo((prev: any) => ({
          ...prev,
          [flightId]: res,
        }))
        // setFlightInfo({
        //   ...flightInfo,
        //   [flightId]: res,
        // })
      }
    })
  }, [activeFlights])

  return (
    <>
      <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
        <LiveMap
          className=''
          airportsData={airportsData}
          markers={markers}
          flightInfo={flightInfo}
        />
      </div>
      <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
        <LiveFlightsTable className='' markers={markers} flightInfo={flightInfo} />
      </div>
    </>
  )
}

const LiveMapWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Live Map'}</PageTitle>
      <LiveMapPage />
    </>
  )
}

export {LiveMapWrapper}
