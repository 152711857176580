import * as Yup from 'yup'

export interface IjumpseatOrDeadhead {
  arrIcao: string
}

const jumpseatOrDeadheadSchemas = Yup.object({
  arrIcao: Yup.string().required().label('Arrival Location'),
})

export {jumpseatOrDeadheadSchemas}
