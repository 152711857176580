/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

type Props = {
  operators: string[]
}

const Step1: FC<Props> = ({operators}: Props) => {
  useEffect(() => {}, [operators])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bold d-flex align-items-center text-dark'>
          Choose Carrier
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-semibold fs-6'>
          This will filter all available schedules for the selected carrier.
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          {!!operators?.length &&
            operators.map((operator: any) => {
              return (
                <div className='col-lg-6' key={'operator' + operator}>
                  <Field
                    type='radio'
                    className='btn-check'
                    name='operator'
                    value={operator}
                    id={operator}
                  />
                  <label
                    className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                    htmlFor={operator}
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com005.svg'
                      className='svg-icon-3x me-5'
                    />

                    <span className='d-block fw-semibold text-start'>
                      <span className='text-dark fw-bold d-block fs-4 mb-2'>{operator}</span>
                      {/* <span className='text-gray-400 fw-semibold fs-6'>
                  If you need more info, please check it out
                </span> */}
                    </span>
                  </label>
                </div>
              )
            })}

          <div className='text-danger mt-2'>
            <ErrorMessage name='operator' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
