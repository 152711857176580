import {IbidLineQuery} from '../app/pages/bid-line/components/CreateBidLineWizardHelper'
import {CARRIER_ID, CHECK_WX_API_KEY, API_URL} from '../config'
import {xml2json} from './xml2js'

export function parseXml(xml: any) {
  var dom = null
  if (window.DOMParser) {
    try {
      dom = new DOMParser().parseFromString(xml, 'text/xml')
    } catch (e) {
      dom = null
    }
  }
  // @ts-ignore
  else if (window.ActiveXObject) {
    try {
      // @ts-ignore
      dom = new ActiveXObject('Microsoft.XMLDOM')
      dom.async = false
      if (!dom.loadXML(xml))
        // parse error ..

        window.alert(dom.parseError.reason + dom.parseError.srcText)
    } catch (e) {
      dom = null
    }
  } else alert('cannot parse xml string!')
  return dom
}

const getAuthToken = () => {
  const token = localStorage.getItem('_t')
  return token || ''
}

// Decode token to get user id
const decodeToken = () => {
  const token = getAuthToken()
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const decodedToken = JSON.parse(window.atob(base64))
  return decodedToken
}

class Store {
  public data: any

  constructor() {
    this.data = {}
  }

  public set(key: string, value: any) {
    this.data[key] = value
  }

  public get(key: string) {
    return this.data[key]
  }
}

export const store = new Store()

export const getDasboardStats = async () => {
  try {
    const req = await fetch(`${API_URL}stats/dashboard?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getGeneralStats = async () => {
  try {
    const req = await fetch(`${API_URL}stats?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getUserStats = async () => {
  const currentUser = decodeToken()
  try {
    const req = await fetch(
      `${API_URL}users/${currentUser.payload.public_id}?carrierId=${CARRIER_ID}`,
      {
        headers: {
          'x-access-token': getAuthToken(),
        },
      }
    )
    const response = await req.json()

    store.set('user', response.data)

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getUserStatsByName = async (name: string) => {
  try {
    const req = await fetch(`${API_URL}users/name/${name}?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })

    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getCrewbaseStats = async () => {
  try {
    const req = await fetch(`${API_URL}stats/crewbase?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getRouteChangelogTime = async () => {
  try {
    const req = await fetch(`${API_URL}schedules/changelog/time?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getUsers = async () => {
  try {
    const req = await fetch(`${API_URL}users?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getTopPilots = async (startDate?: any, endDate?:any) => {
  try {
    const req = await fetch(`${API_URL}usersV2?carrierId=${CARRIER_ID}&startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getSchedules = async (query?: any) => {
  try {
    // Convert query object to query string if query is provided
    const queryString = query
      ? Object.keys(query)
          .map((key) => `${key}=${(query as any)[key]}`)
          .join('&')
      : ''

    const req = await fetch(`${API_URL}schedules?${queryString}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const createBid = async (scheduleId: string) => {
  try {
    const req = await fetch(`${API_URL}bids`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        scheduleId,
      }),
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getAirportByIcao = async (icao: string) => {
  try {
    const req = await fetch(`${API_URL}airports/${icao}?carrierId=${CARRIER_ID}`)
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getWeatherByIcao = async (icao: string) => {
  try {
    const req = await fetch(`https://api.checkwx.com/metar/${icao}/decoded?pretty=1`, {
      headers: {
        'X-API-Key': CHECK_WX_API_KEY,
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getAllFlightsByIcao = async (icao: string) => {
  try {
    const req = await fetch(`${API_URL}flights/latest/${icao}?carrierId=${CARRIER_ID}&limit=5`)
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getAirports = async () => {
  try {
    const req = await fetch(`${API_URL}airports?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getFlightById = async (id: string) => {
  try {
    const req = await fetch(`${API_URL}flight/${id}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getPositionReportsByFlightId = async (id: string) => {
  try {
    const req = await fetch(`${API_URL}reports/${id}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getCarrierInfo = async () => {
  try {
    const req = await fetch(`${API_URL}carrier?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getBidLine = async () => {
  try {
    const req = await fetch(`${API_URL}bids/line/${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const generateBidLine = async (bidLineQuery: IbidLineQuery) => {
  try {
    const req = await fetch(`${API_URL}bids/line/generate`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...bidLineQuery,
        carrierId: CARRIER_ID,
      }),
    })
    const response = await req.json()
    if (response?.data?.sequence.length < 1) return false

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const saveBidLine = async (
  blidLineMap: string[],
  deadheadMap: any[] | undefined = undefined
) => {
  try {
    const req = await fetch(`${API_URL}bids/line/save`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        carrierId: CARRIER_ID,
        map: blidLineMap,
        deadheadMap,
      }),
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const deleteBidLine = async (bidId: string) => {
  try {
    const req = await fetch(`${API_URL}bids/line/${bidId}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getHistoricalBidLine = async () => {
  try {
    const req = await fetch(`${API_URL}bids/line/historical/${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getBids = async () => {
  try {
    const req = await fetch(`${API_URL}bids?carrierId=${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const deleteBid = async (bidId: string) => {
  try {
    const req = await fetch(`${API_URL}bids/${bidId}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getLiveFlightUpdates = async () => {
  try {
    const req = await fetch('https://kv.rotate.group', {
      method: 'POST',
      body: JSON.stringify({
        command: 'GET',
        key: `live-${CARRIER_ID}-*`,
      }),
    })
    const response = await req.json()

    return response
  } catch (err) {
    console.error(err)
  }
}

export const getDiscordConnectLink = async () => {
  try {
    const req = await fetch(`${API_URL}discord/${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const removeDiscordConnect = async () => {
  try {
    const req = await fetch(`${API_URL}discord/remove/${CARRIER_ID}`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getUserFormData = async () => {
  try {
    const req = await fetch(`${API_URL}users/form`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const updateUserFormData = async (data: any) => {
  try {
    const req = await fetch(`${API_URL}users/form`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: data.first_name ?? '',
        lastName: data.last_name ?? '',
        location: data.location ?? '',
        simbriefId: data.simbrief_id ?? '',
        posconId: data.poscon_id ?? '',
        vatsimId: data.vatsim_id ?? '',
        ivaoId: data.ivao_id ?? '',
      }),
    })

    if (req.status !== 200) {
      return false
    }

    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const uploadUserAvatar = async (data: FormData) => {
  try {
    const req = await fetch(`${API_URL}users/image/upload`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
      },
      body: data,
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getUserEmailSettings = async () => {
  try {
    const req = await fetch(`${API_URL}users/email/settings`, {
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const updateUserEmailSettings = async (data: any) => {
  try {
    const req = await fetch(`${API_URL}users/email/settings`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailComments: data.email_comments,
        emailAnnouncements: data.email_announcements,
        emailWarnings: data.email_warnings,
      }),
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getSimbriefByBidId = async (bidId: string, simbriefId: string) => {
  try {
    let param: string = ''
    const options = {
      method: 'GET',
    }
    // Check if simbriefId contains numbers only
    if (simbriefId.match(/^[0-9]+$/)) {
      param = `?userId=${simbriefId}`
    } else {
      param = `?username=${simbriefId}`
    }
    const req = await fetch(
      `https://www.simbrief.com/api/xml.fetcher.php?${param}&static_id=${bidId}`,
      options
    )
    const res = parseXml(await req.text())
    const json = xml2json(res)
    if (req.status !== 200) {
      return false
    }
    return JSON.parse(json.replace('undefined', '')).OFP
  } catch (err) {
    console.error(err)
  }
}

export const getVatsimEvents = async () => {
  try {
    const req = await fetch(`${API_URL}vatsim/events`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const transferBase = async (baseId: string) => {
  try {
    const req = await fetch(`${API_URL}users/base`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        airportId: baseId,
        carrierId: CARRIER_ID,
      }),
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getUserAwards = async () => {
  try {
    const req = await fetch(`${API_URL}users/awards/${CARRIER_ID}`, {
      method: 'GET',
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const jumpseatOrDeadhead = async (arrIcao: string, isJumpseat: boolean = false) => {
  try {
    const req = await fetch(`${API_URL}flights/jumpseat`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        arrIcao,
        isJumpseat,
        carrierId: CARRIER_ID,
      }),
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getJumpseatQuote = async (arrIcao: string) => {
  try {
    const req = await fetch(`${API_URL}flights/jumpseat/quote`, {
      method: 'POST',
      headers: {
        'x-access-token': getAuthToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        arrIcao,
        carrierId: CARRIER_ID,
      }),
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export const getUserPoints = async () => {
  try {
    const req = await fetch(`${API_URL}users/points/${CARRIER_ID}`, {
      method: 'GET',
      headers: {
        'x-access-token': getAuthToken(),
      },
    })
    const response = await req.json()

    return response.data
  } catch (err) {
    console.error(err)
  }
}

export default {}
