import {useCallback, useState} from 'react'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {useQuery} from 'react-query'
import {IjumpseatOrDeadhead, jumpseatOrDeadheadSchemas} from './JumpseatWidgetHelper'
import {getAirports, getJumpseatQuote, getUserPoints, jumpseatOrDeadhead} from '../../../middleware'

const Alert: React.FC<{error: boolean; msg: string}> = ({error, msg}) => {
  if (!msg) return <></>
  if (error) {
    return <div className='absolute top-0 badge badge-light-danger p-12'>{msg}</div>
  }
  return <div className='badge badge-light-success p-12'>{msg}</div>
}

const JumpseatWidget = () => {
  const [initValues] = useState<IjumpseatOrDeadhead>({
    arrIcao: '',
  })
  const [quotePoints, setQuotePoints] = useState<any>()
  const [quoteIcao, setQuoteIcao] = useState<any>()
  const [msg, setMsg] = useState<any>()
  const [jumpseatIsLoading, setJumpseatIsLoading] = useState(false)

  const handleGenerateQuote = useCallback(
    async (e: any) => {
      e.preventDefault()
      const data = await getJumpseatQuote(quoteIcao)

      if (!data?.points) {
        setMsg({
          error: true,
          msg: data?.[0]?.path?.[0] ? data?.[0]?.path?.[0] + ' Required' : data.toString(),
        })
        setTimeout(() => {
          setMsg(undefined)
        }, 5000)
        return
      }

      setQuotePoints(data.points)
    },
    [setQuotePoints, quoteIcao]
  )

  const handleJumpseatSubmit = useCallback(
    async (values: FormikValues) => {
      if (!values.arrIcao) {
        return
      }
      setJumpseatIsLoading(true)

      try {
        const jumpseat = await jumpseatOrDeadhead(values.arrIcao, true)

        if (!jumpseat) {
          setMsg({error: true, msg: 'Something went wrong, please try again'})
        } else {
          setMsg({
            error: false,
            msg: `Jumpseat confirmed, you are now located at ${jumpseat?.arr_icao}`,
          })
        }

        setTimeout(() => {
          setMsg(undefined)
        }, 5000)
      } catch (err) {
        console.error(err)
      } finally {
        setJumpseatIsLoading(false)
      }
    },
    [setJumpseatIsLoading]
  )

  const {data: airportsData} = useQuery('airportsData', getAirports, {
    staleTime: Infinity,
    refetchOnMount: true,
  })

  const {data: pointsData} = useQuery('pointsData', getUserPoints, {
    staleTime: Infinity,
    refetchOnMount: true,
  })

  const checkValidation = useCallback(() => {
    return ''
  }, [])

  return (
    <>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik
          validationSchema={jumpseatOrDeadheadSchemas}
          initialValues={initValues}
          onSubmit={handleJumpseatSubmit}
        >
          {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='mr-2'></div>

              {/* This page will allow users to select an airport from the dropdown/search then generate a jumpseat quote if the user likes the jumpseat quote then they may press submit to transfer the users location */}

              <div className='mb-13 text-center'>
                <h1 className='mb-3'>Jumpseat</h1>
                <div className='text-muted fw-bold fs-5'>
                  Please select an airport to generate a jumpseat quote
                  {/* <a href='#' className='link-primary fw-bolder'>
                  Pricing Guidelines
                </a> */}
                  .
                </div>
                <br />
                <Alert error={msg?.error} msg={msg?.msg} />
              </div>

              <div className='fv-row row pt-10'>
                <div className='col'>
                  {/* Create autoselect dropdown box for airport selection  */}
                  <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-dark fs-6'>Arrival Airport</label>
                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='arrIcao'
                      placeholder='Enter airport ICAO'
                      onInput={(e: any) => {
                        setQuoteIcao(e.target.value)
                      }}
                      autoComplete='off'
                    />
                    <ErrorMessage
                      name='arrIcao'
                      component='div'
                      className='fv-plugins-message-container invalid-feedback'
                    />
                  </div>

                  {/* Submit button */}

                  <div className='text-center'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={jumpseatIsLoading}
                      onClick={handleGenerateQuote}
                    >
                      {jumpseatIsLoading ? (
                        <>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          <span className='align-middle'>Loading...</span>
                        </>
                      ) : (
                        'Generate Quote'
                      )}
                    </button>
                  </div>
                </div>

                {/* Display generated quote */}
                <div className='text-center col'>
                  {/* Gradient box with a $ followed by a price */}

                  <div className='text-center bg-light rounded p-4 mb-10'>
                    <div className='d-flex flex-row-fluid  flex-center bg-body rounded'>
                      <div className='py-10 px-5'>
                        <div className='d-flex flex-column flex-center position-relative'>
                          <div className='fw-bolder fs-1 text-dark'>
                            {quotePoints ?? '---'} Points
                          </div>

                          <hr className='bg-dark opacity-25 w-100' />
                          <div className='fw-bold fs-6 text-muted'>
                            Current Balance: {Number(pointsData).toFixed(0) ?? '0'}
                          </div>
                          <div className='fw-bold fs-6 text-muted'>
                            New Balance:{' '}
                            {quotePoints ? (Number(pointsData) - quotePoints).toFixed(0) : '---'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Submit button */}

                  {quotePoints && (
                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-success w-100 mb-5'
                        disabled={jumpseatIsLoading}
                      >
                        {jumpseatIsLoading ? (
                          <>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            <span className='align-middle'>Loading...</span>
                          </>
                        ) : (
                          'Confirm'
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export {JumpseatWidget}
