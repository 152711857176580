import {getUsers} from '../../../middleware'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import {SchedulesTable} from '../../../_metronic/partials/widgets/tables/SchedulesTable'

const cacheTime = 5 * 60000
const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SchedulesPage = () => {
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <SchedulesTable className='' />
      </div>
    </>
  )
}

const SchedulesWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Schedules'}</PageTitle>
      <SchedulesPage />
    </>
  )
}

export {SchedulesWrapper}
