import {useCallback, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getAirports} from '../../../middleware'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {RouteMap} from './RouteMap'
import {SchedulesTable} from '../../../_metronic/partials/widgets/tables/SchedulesTable'

const cacheTime = 5 * 60000
const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RouteMapPage = () => {
  const [schedules, setSchedules] = useState([])
  const [selectedDeparture, setSelectedDeparture] = useState('')

  const {
    data: airportsData,
    isLoading: airportsLoading,
    error: airportsError,
  } = useQuery('airports', getAirports, {
    cacheTime,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  })

  const schedulesOnChange = useCallback(
    (data: any) => {
      selectedDeparture ? setSchedules(data) : setSchedules([])
    },
    [selectedDeparture]
  )

  return (
    <>
      <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
        <RouteMap
          className=''
          airportsData={airportsData}
          schedules={schedules}
          setSelectedDeparture={setSelectedDeparture}
          selectedDeparture={selectedDeparture}
        />
      </div>
      <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
        <SchedulesTable depIcao={selectedDeparture} className='' onChange={schedulesOnChange} />
      </div>
    </>
  )
}

const RouteMapWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Route Map'}</PageTitle>
      <RouteMapPage />
    </>
  )
}

export {RouteMapWrapper}
