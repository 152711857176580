import {getUsers} from '../../../middleware'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import {RosterTable} from '../../../_metronic/partials/widgets/tables/RosterTable'

const cacheTime = 5 * 60000

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RosterPage = () => {
  const {
    data: usersData,
    isLoading: dashboardDataIsLoading,
    isError: _,
  } = useQuery('rosterData', getUsers, {cacheTime})

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <RosterTable data={usersData} loading={dashboardDataIsLoading} className='col-xxl-12' />
      </div>
    </>
  )
}

const RosterWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Roster'}</PageTitle>
      <RosterPage />
    </>
  )
}

export {RosterWrapper}
