import {flagEnableDeadhead} from '../../../../middleware/flags'

type Props = {
  data: any
  loading: boolean
}

type ScheduleData = {
  public_id: string
  active: boolean
  aircraft_type: string
  altitude: number
  arr_icao: string
  dep_icao: string
  callsign: string
  flight_number: number
  est_flight_time: number
  notes: string
  route: string
  updated_at: string
  // For form
  added?: boolean
}

export const BidTable = ({data, loading}: Props) => {
  const {schedules, deadheads} = data
  const enableDeadheads = flagEnableDeadhead()
  return (
    <>
      {/* begin::Table container */}
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th>Leg</th>
              <th className='min-w-150px'>Flight Number</th>
              <th className='min-w-140px'>Route</th>
              <th className='min-w-120px'>Equipment</th>
              <th className='min-w-120px'>Flight Time</th>
              <th className='min-w-120px'>Notes</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {schedules?.length ? (
              // get first n records
              schedules.map((item: ScheduleData, i: number) => {
                const selectedDeadhead =
                  enableDeadheads &&
                  deadheads?.length &&
                  deadheads.find((dh: any, i: number) => dh.prevScheduleId === item.public_id)
                return (
                  <>
                    <tr key={'schedule-' + item.public_id}>
                      <td>
                        {i + 1 + (selectedDeadhead?.nextScheduleId === item.public_id ? 1 : 0)}
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {item.callsign + item.flight_number}
                        </a>
                      </td>
                      <td style={{maxWidth: 400}}>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.dep_icao + ' - ' + item.arr_icao}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {item.route}
                        </span>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.aircraft_type}
                        </a>
                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            Code: Paid
                          </span> */}
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.est_flight_time}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Hours
                        </span>
                      </td>
                      <td className='text-dark text-hover-primary fs-6'>{item.notes}</td>
                    </tr>
                    {!!selectedDeadhead && (
                      <tr
                        key={'dh-' + selectedDeadhead.public_id}
                        // Add styling to show that this flight leg is not part of the sequence as it is a deadhead it should be subtle
                        className='bg-light-warning outline-dashed border border-dashed border-gray-300 text-gray-600'
                      >
                        <td>-</td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {selectedDeadhead.callsign + selectedDeadhead.flight_number}
                          </a>
                        </td>
                        <td style={{maxWidth: 400}}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {selectedDeadhead.depIcao + ' - ' + selectedDeadhead.arrIcao}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {item.route}
                          </span>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {selectedDeadhead.aircraft_type}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            Code: Paid
                          </span> */}
                        </td>
                        <td>
                          {selectedDeadhead.est_flight_time}

                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            Hours
                          </span>
                        </td>
                        <td className='text-hover-primary fs-6'>{selectedDeadhead.notes}</td>
                      </tr>
                    )}
                  </>
                )
              })
            ) : loading ? (
              <tr>
                <td colSpan={7}>Loading Flights...</td>
              </tr>
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-semibold'>
                        <h4 className='text-gray-800 fw-bold'>Sequence not found</h4>
                        <div className='fs-6 text-gray-600'>
                          Unable to generate line with the current parameters. Please try again.
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
    </>
  )
}
