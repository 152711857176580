import moment from 'moment'
import {useMemo} from 'react'
import {useQuery} from 'react-query'
import {getAirports, getRouteChangelogTime} from '../../../middleware'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const RouteChangelogPage = () => {
  const {data: routeChangelogData, isLoading: routeChangelogDataIsLoading} = useQuery(
    'routeChangelogData',
    getRouteChangelogTime,
    {
      staleTime: Infinity,
      refetchOnMount: true,
    }
  )

  const {data: airportsData, refetch: refetchAirportsData} = useQuery('airportsData', getAirports, {
    staleTime: Infinity,
    refetchOnMount: true,
  })

  const routeChangelogDataWithAirports = useMemo(() => {
    if (airportsData?.length && routeChangelogData?.length) {
      const a: any = {}
      airportsData.forEach((airP: any) => {
        a[airP.icao] = airP
      })

      return routeChangelogData.map((item: any) => {
        item.schedules.forEach((route: any) => {
          if (a[route.dep_icao]) {
            route.dep_airport = a[route.dep_icao]
          }
          if (a[route.arr_icao]) {
            route.arr_airport = a[route.arr_icao]
          }
          return route
        })
      })
    }
  }, [airportsData, routeChangelogData?.length])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        {!routeChangelogDataWithAirports?.length && (
          <div className='col-12'>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-body border-top p-9'>
                <div className='row mb-7'>
                  <div className='col-12'>
                    <h3 className='fw-bold m-0'>Loading...</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!!routeChangelogDataWithAirports?.length &&
          routeChangelogData.map((item: any, index: number) => {
            return (
              <div className='card mb-5 mb-xl-10'>
                <div
                  className='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target={'#log' + item.date}
                  aria-expanded='true'
                  aria-controls={'log' + item.date}
                >
                  <div className='card-title m-0'>
                    <h3 className='fw-bold m-0'>{moment(item.date).format('MMMM DD, YYYY')}</h3>
                  </div>
                </div>
                <div id={'log' + item.date} className={index === 0 ? 'collapse show' : 'collapse'}>
                  <div className='card-body border-top p-9'>
                    <div className='row mb-7'>
                      <table className='table align-middle gs-0 gy-5'>
                        {/* begin::Table head */}
                        <thead>
                          <tr>
                            <th className='p-0 min-w-200px'></th>
                            <th className='p-0 min-w-200px'></th>
                            <th className='p-0 min-w-200px'></th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {item?.schedules.map((route: any, index: number) => {
                            if (
                              routeChangelogData?.length &&
                              airportsData?.length &&
                              !route?.dep_airport?.name
                            ) {
                              console.log('Cannot find icao for', route?.arr_icao)
                            }
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {route?.dep_icao} - {route?.dep_airport?.name}
                                </td>
                                <td>
                                  {route?.arr_icao} - {route?.arr_airport?.name}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export const RouteChangelogWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Route Changelog'}</PageTitle>
      <RouteChangelogPage />
    </>
  )
}
