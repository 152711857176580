export const CARRIER_ID = '1066f579-a0f1-41c1-a581-e188c7cdfb77'
export const SSO_URL = 'https://sso.rotate.group/'
export const CHECK_WX_API_KEY = '5f834ed10513dd66e5d3850fb5'
export const CARRIER_CALLSIGN = 'FFT'
export const MAPBOX_URI =
  'https://api.mapbox.com/styles/v1/edwinbrowwn/ckkqk5zot1c8617o9eboaez4u/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZWR3aW5icm93d24iLCJhIjoiY2trcWs0YWZjMDg0bzJ1cXQyODB1NnNydSJ9.tlLwEw7sB5aNaHxQTpqkRw'
export let API_URL =
  process.env.NODE_ENV === 'development'
    ? //'http://localhost:8787/'
      'https://api-v2.rotate.group/'
    : 'https://api-v2.rotate.group/'

export const styleMapLine = '#0366b4'
export const styleDarkBg = '#181a1b' //#181a1b // _variable.scss $page-bg-dark:
export const styleDarkGbLighter = '#202224'

export const typeGroupings = [{name: 'Airbus 320/20N/321/21N', id: 'A320,A321,A20N,A21N'}]
