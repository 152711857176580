/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  data: FlightData[]
  title?: string
}

type FlightData = {
  public_id: string
  status: string
  flight: string
  lr: number
  schedule: {
    dep_icao: string
    arr_icao: string
    name: string
  }
  flight_time: number
  aircraft_type: string
  approved: boolean
  dep_time: string
  arr_time: string
  user: {
    avatar_url: string
    username: string
  }
}

const FlightRow: React.FC<{flight: FlightData; index: number}> = ({flight, index}) => {
  return (
    <tr key={'flight-' + index}>
      <td>
        <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
          {flight.schedule.dep_icao + ' - ' + flight.schedule.arr_icao}
        </p>
        <span className='text-muted fw-semibold d-block'>
          Flight Time: {flight.flight_time?.toFixed(2)} hrs
        </span>
      </td>
      <td>
        <span className='text-muted fw-semibold d-block'>Landing Rate: {flight.lr} fpm</span>
      </td>
      <td className='text-end text-muted fw-semibold'>
        {new Date(flight.dep_time).toLocaleDateString() +
          ' ' +
          new Date(flight.dep_time).toLocaleTimeString()}{' '}
        - {new Date(flight.arr_time).toLocaleTimeString()}
      </td>
      <td className='text-end'>
        <span
          className={flight.approved ? 'badge badge-light-success' : 'badge badge-light-warning '}
        >
          {flight.approved ? 'Approved' : 'Denied'}
        </span>
      </td>
      <td className='text-end'>
        <a
          href={`/route/${flight?.public_id}`}
          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
        >
          <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
        </a>
      </td>
    </tr>
  )
}

export const FlightsTable: React.FC<Props> = ({className, data, title = 'Latest Flights'}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>More than 400 new products</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='border-0'>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-50px'></th>
                <th className='p-0 min-w-140px'></th>
                <th className='p-0 min-w-110px'></th>
                <th className='p-0 min-w-50px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data?.length &&
                data
                  .sort(
                    (a: any, b: any) =>
                      new Date(b.arr_time).getTime() - new Date(a.arr_time).getTime()
                  )
                  .map((flight, index) => FlightRow({flight, index}))}
            </tbody>
            {/* end::Table body */}
          </table>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}
