import moment from 'moment'
import {useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
  deleteBidLine,
  getAirports,
  getBidLine,
  getHistoricalBidLine,
  jumpseatOrDeadhead,
} from '../../../middleware'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Alert: React.FC<{error: boolean; msg: string}> = ({error, msg}) => {
  if (!msg) return <></>
  if (error) {
    return (
      <div className='row g-5 g-xl-8 pb-6'>
        <div className='col-12 px-0'>
          <div className='badge badge-light-danger p-12' style={{width: '100%'}}>
            {msg}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='row g-5 g-xl-8 pb-6'>
      <div className='col-12 px-0'>
        <div className='badge badge-light-success p-12' style={{width: '100%'}}>
          {msg}
        </div>
      </div>
    </div>
  )
}

export const ActiveBidLinePage = () => {
  const {
    data: bidLineData,
    isLoading: bidLineIsLoading,
    error: bidLineError,
    refetch: refetchBidLineData,
  } = useQuery('bidLineData', getBidLine, {})

  const {
    data: historicalBidLineData,
    isLoading: historicalBidLineIsLoading,
    error: historicalBidLineError,
  } = useQuery('histtoricalBidLineData', getHistoricalBidLine, {})

  const {data: airportsData, refetch: refetchAirportsData} = useQuery('airportsData', getAirports, {
    staleTime: Infinity,
    refetchOnMount: true,
  })

  const [alertMessage, setAlertMessage] = useState<string>('')
  const [bidLineDeletedError, setBidLineDeletedError] = useState<boolean>(false)

  const linesWithRef = useMemo(() => {
    if (airportsData?.length && historicalBidLineData?.length) {
      const a: any = {}
      airportsData.forEach((airP: any) => {
        a[airP.icao] = airP
      })

      return historicalBidLineData.map((item: any) => {
        return {
          ...item,
          schedules: item?.schedules.map((route: any) => {
            if (a[route.dep_icao]) {
              route.dep_airport = a[route.dep_icao]
            }
            if (a[route.arr_icao]) {
              route.arr_airport = a[route.arr_icao]
            }
            return route
          }),
        }
      })
    }
    return []
  }, [historicalBidLineData, airportsData])

  return (
    <>
      {!bidLineData?.[0]?.bidLineItems?.length && !bidLineIsLoading && (
        <div className='row g-5 g-xl-8'>
          <div className='col-12 px-0'>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-body border-top p-9'>
                <div className='row mb-7'>
                  <div className='col-12'>
                    <h3 className='fw-bold m-0'>No Active Bid Line</h3>
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-solid p-6 mt-10'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen040.svg'
                        className='svg-icon-2tx svg-icon-warning me-4'
                      />
                      <div className='d-flex flex-stack flex-grow-1'>
                        <div className='fw-semibold'>
                          <h4 className='text-gray-800 fw-bold'>No Active Bid Line</h4>
                          <div className='fs-6 text-gray-600'>
                            Submit a trip request to start a bid line
                            <a href='/pbs' className='fw-bold'>
                              {' '}
                              Trip Request
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Alert error={bidLineDeletedError} msg={alertMessage} />
      <div className='row g-5 g-xl-8'>
        {!!bidLineData?.[0]?.bidLineItems?.[0] && (
          <div className='col-12 px-0'>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-header border-0'>
                <div className='card-title m-0'>
                  <h3 className='fw-bold m-0 py-6'>
                    {bidLineData[0].bids[0]?.schedule.callsign}
                    {bidLineData[0].public_id.split('-')[0].toUpperCase()}
                    <br></br>
                    <span className='text-muted fs-5'>In Progress</span>
                  </h3>
                </div>
                <div className='me-1 px-4 py-2 d-flex'>
                  <button
                    className='btn btn-md btn-light-primary btn-active-dark-primary my-auto px-4 py-2'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target={'#log' + new Date(bidLineData[0].created_at).getTime()}
                    aria-expanded='true'
                    aria-controls={'log' + new Date(bidLineData[0].created_at).getTime()}
                    style={{marginRight: '15px'}}
                  >
                    Expand
                  </button>
                  <button
                    className='btn btn-md btn-light-danger btn-active-dark-primary my-auto px-4 py-2'
                    onClick={async (e) => {
                      e.preventDefault()
                      const deleted = await deleteBidLine(bidLineData[0].public_id)
                      if (deleted) {
                        setBidLineDeletedError(false)
                        setAlertMessage('Bid Line Deleted')
                        refetchBidLineData()
                      } else {
                        setBidLineDeletedError(true)
                        setAlertMessage('Error deleting bid line')
                      }
                      setTimeout(() => {
                        setAlertMessage('')
                      }, 5000)
                    }}
                  >
                    Delete With Bids
                  </button>
                </div>
              </div>
              <div
                id={'log' + new Date(bidLineData[0].created_at).getTime()}
                className={'collapse show'}
              >
                <div className='card-body border-top px-14'>
                  <div className='row mb-7'>
                    <table className='table align-middle gs-0 gy-5'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-50px'>Leg</th>
                          <th className='min-w-50px'>Flight Number</th>
                          <th className='min-w-140px'>Equipment</th>
                          <th className='min-w-140px'>EST Duration</th>
                          <th className='min-w-140px'>Departure Airport</th>
                          <th className='min-w-140px'>Arrival Airport</th>
                          <th className='min-w-140px'>Status</th>
                          <th className='min-w-140px'>Date Added</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {bidLineData[0]?.bidLineItems &&
                          bidLineData[0].bidLineItems.map((bid: any, index: number) => {
                            const selectedDeadhead =
                              bidLineData[0]?.deadheads?.length &&
                              bidLineData[0]?.deadheads.find(
                                (dh: any, i: number) =>
                                  dh.prevScheduleId === bid?.schedule?.public_id
                              )

                            if (!bid.schedule_id) {
                              const prevFlight = bidLineData[0]?.bidLineItems?.find(
                                (dh: any, i: number) => dh.schedule_id === bid?.prevScheduleId
                              )
                              return (
                                <tr key={bid?.schedule_id + index + 'dh'}>
                                  {/* <td>{index + 2}</td>
                                  <td>{selectedDeadhead?.nextSchedule?.flight_number}</td>
                                  <td>{selectedDeadhead?.nextSchedule?.aircraft_type}</td>
                                  <td>{selectedDeadhead?.nextSchedule?.est_flight_time}</td>
                                  <td>{selectedDeadhead?.nextSchedule?.dep_icao}</td>
                                  <td>{selectedDeadhead?.nextSchedule?.arr_icao}</td>
                                  <td>
                                    {selectedDeadhead?.completed}
                                    <button
                                      className={
                                        selectedDeadhead?.completed
                                          ? 'btn btn-light-success'
                                          : 'btn btn-light-warning'
                                      }
                                    >
                                      {selectedDeadhead?.completed ? 'Completed' : 'In Progress'}
                                    </button>
                                  </td>
                                  <td>
                                    {moment(selectedDeadhead?.created_at).format('MMMM DD, YYYY')}
                                  </td> */}
                                  <td colSpan={1}>-</td>
                                  <td colSpan={1}>Deadhead Leg</td>
                                  <td colSpan={1}>{bid?.aircraftType}</td>
                                  <td colSpan={1}>-</td>
                                  <td colSpan={1}>{bid?.depIcao}</td>
                                  <td colSpan={1}>{bid?.arrIcao}</td>
                                  <td colSpan={1}>
                                    <span
                                      className={
                                        bid?.completed
                                          ? 'badge badge-light-success'
                                          : 'badge badge-light-warning '
                                      }
                                    >
                                      {bid?.completed
                                        ? 'Completed'
                                        : bid?.completed
                                        ? 'Now Boarding'
                                        : 'Boarding Soon'}
                                    </span>
                                  </td>
                                  <td colSpan={1}>
                                    <div className='d-flex align-items-center pe-14'>
                                      {/* This is a Row that display the DEP and ARR icao for the deadhead with a "Travel" action Button - minimal and modern css */}

                                      <button
                                        className='btn btn-sm btn-light-primary fw-bolder px-4 py-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_invite_friends'
                                        disabled={prevFlight?.completed ? bid?.completed : true}
                                        onClick={async (e) => {
                                          e.preventDefault()
                                          const dh = await jumpseatOrDeadhead(bid.arrIcao)

                                          if (!dh?.public_id) {
                                            setBidLineDeletedError(true)
                                            setAlertMessage('Error completing deadhead')
                                            setTimeout(() => {
                                              setAlertMessage('')
                                            }, 5000)
                                          } else {
                                            await refetchBidLineData()
                                          }
                                        }}
                                      >
                                        Travel to {bid?.arrIcao}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }
                            return (
                              <tr key={bid?.schedule_id + index}>
                                <td>
                                  {index +
                                    1 +
                                    (selectedDeadhead?.nextScheduleId === bid?.schedule?.public_id
                                      ? 1
                                      : 0)}
                                </td>
                                <td>{bid?.schedule?.flight_number}</td>
                                <td>{bid?.schedule?.aircraft_type}</td>
                                <td>{bid?.schedule?.est_flight_time}</td>
                                <td>{bid?.schedule?.dep_icao}</td>
                                <td>{bid?.schedule?.arr_icao}</td>
                                <td>
                                  {bid?.completed}
                                  <span
                                    className={
                                      bid?.completed
                                        ? 'badge badge-light-success'
                                        : 'badge badge-light-warning '
                                    }
                                  >
                                    {bid?.completed ? 'Completed' : 'In Progress'}
                                  </span>
                                </td>
                                <td>{moment(bid?.created_at).format('MMMM DD, YYYY')}</td>
                              </tr>
                            )
                          })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='row g-5 g-xl-8'>
        {!linesWithRef?.length && historicalBidLineIsLoading && (
          <div className='col-12'>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-body border-top p-9'>
                <div className='row mb-7'>
                  <div className='col-12'>
                    <h3 className='fw-bold m-0'>Loading...</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!!linesWithRef?.length &&
          linesWithRef.map((item: any, index: number) => {
            return (
              <div className='card mb-5 mb-xl-10' key={item.pupblic_id}>
                <div
                  className='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target={'#log' + new Date(item.created_at).getTime()}
                  aria-expanded='true'
                  aria-controls={'log' + new Date(item.created_at).getTime()}
                >
                  <div className='card-title m-0'>
                    <h3 className='fw-bold m-0 py-6'>
                      {item?.schedules[0].callsign}
                      {item.public_id.split('-')[0].toUpperCase()}
                      <br></br>
                      <span className='text-muted fs-5'>
                        Completed: {moment(item.updated_at).format('MMMM DD, YYYY h:mm A')}
                      </span>
                    </h3>
                  </div>
                  <div className='btn btn-md btn-light-primary btn-active-dark-primary my-auto me-1 px-4 py-2'>
                    Expand
                  </div>
                </div>
                <div id={'log' + new Date(item.created_at).getTime()} className={'collapse'}>
                  <div className='card-body border-top '>
                    <div className='row mb-7'>
                      <table className='table align-middle gs-0 gy-5'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-50px'>Leg</th>
                            <th className='min-w-140px'>Flight Number</th>
                            <th className='min-w-140px'>Departure Airport</th>
                            <th className='min-w-140px'>Arrival Airport</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {item?.schedules.map((route: any, index: number) => {
                            return (
                              <tr key={route.public_id + index}>
                                <td>{index + 1}</td>
                                <td>{route?.flight_number}</td>
                                <td>
                                  {route?.dep_icao} - {route?.dep_airport?.name}
                                </td>
                                <td>
                                  {route?.arr_icao} - {route?.arr_airport?.name}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export const ActiveBidLineWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Bid Line'}</PageTitle>
      <ActiveBidLinePage />
    </>
  )
}
