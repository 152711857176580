import moment from 'moment'
import {useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

type Props = {
  className: string
  markers: any
  flightInfo: any
}

const FlightRow: React.FC<any> = (flight) => {
  return (
    <tr key={'flight-' + flight.public_id}>
      <td>
        <a
          href={'/profile/' + flight?.acars.position_report?.username}
          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
        >
          {flight?.acars.position_report?.username}
        </a>
      </td>
      <td>
        <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
          {flight?.schedule?.dep_icao + ' - ' + flight?.schedule?.arr_icao}
        </p>
        <span className='text-muted fw-semibold d-block'>
          Departed {moment(flight?.dep_time).fromNow()}
        </span>
      </td>
      <td>
        {/* <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
          {flight?.schedule?.dep_icao + ' - ' + flight?.schedule?.arr_icao}
        </p> */}
        <span className='text-muted fw-semibold d-block'>
          Altitude: {flight?.acars?.position_report?.altitude} ft
        </span>
      </td>
      <td>
        {/* <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
          {flight?.schedule?.dep_icao + ' - ' + flight?.schedule?.arr_icao}
        </p> */}
        <span className='text-muted fw-semibold d-block'>
          Groundspeed: {flight?.acars?.position_report?.gs} kts
        </span>
      </td>
      <td>
        {/* <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
          {flight?.schedule?.dep_icao + ' - ' + flight?.schedule?.arr_icao}
        </p> */}
        <span className='text-muted fw-semibold d-block'>
          Course: {flight?.acars?.position_report?.heading} deg
        </span>
      </td>
      <td className='text-end'>
        <span
          className={
            ['Landing', 'Landed'].includes(flight?.acars?.position_report?.status)
              ? 'badge badge-light-success'
              : 'badge badge-light-warning '
          }
        >
          {flight?.acars?.position_report?.status}
        </span>
      </td>
      <td className='text-end text-muted fw-semibold'>
        {flight?.schedule?.est_flight_time && (
          <>
            Estimated Arrival:{' '}
            {new Date(
              new Date(flight?.dep_time).getTime() + flight?.schedule?.est_flight_time * 3600000
            ).toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
            (
            {moment(
              new Date(flight?.dep_time).getTime() + flight?.schedule?.est_flight_time * 3600000
            )
              .endOf('hour')
              .fromNow()}
            )
          </>
        )}
      </td>
    </tr>
  )
}

export const LiveFlightsTable: React.FC<Props> = ({className, markers, flightInfo}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Live Flights</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>More than 400 new products</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='border-0'>
                <th className='p-0 w-150px'></th>
                <th className='p-0 w-250px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-140px'></th>
                <th className='p-0 min-w-110px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {!!markers?.length &&
                markers
                  .filter(Boolean)
                  .filter((flight: any) => flightInfo[flight.position_report.flightId])
                  .sort(
                    (m1: any, m2: any) =>
                      new Date(flightInfo[m2.position_report.flightId]?.dep_time).getTime() -
                      new Date(flightInfo[m1.position_report.flightId]?.dep_time).getTime()
                  )
                  .map((flight: any, index: number) => {
                    return FlightRow({
                      ...flightInfo[flight.position_report.flightId],
                      index,
                      acars: flight,
                    })
                  })}
            </tbody>

            {/* end::Table body */}
          </table>
          {!markers?.length && <p>No Current Flights</p>}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}
