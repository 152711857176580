/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { useQuery } from 'react-query'
import { getTopPilots } from '../../../../middleware'
import { log } from 'console'


const cacheTime = 5 * 60000
const staleTime = 5 * 60000
const useQueryOptions = {cacheTime, staleTime}


type Props = {
  className: string
  loading: boolean
}

type MemberData = {
  public_id: string
  flightTime: number
  flights: number
  username: string
  avatar_url: string
}
const date = new Date()
const MembersTable: React.FC<Props> = ({className}) => {
  
  const [startDate,setStartDate] = useState<Date | string>(new Date(date.getFullYear(), date.getMonth(), 1))
  const [endDate,setEndDate] = useState<Date | string>(new Date())
  const [statsButton,setStatsButton] = useState<string>('monthly')

  const {
    data: topPilotData,
    isLoading: topPilotDataIsLoading,
    isFetching: topPilotDataIsFetching,
    refetch: refetchTopPilotData,
  } = useQuery('topPilotData', () => getTopPilots(startDate, endDate), useQueryOptions)

  const loading = topPilotDataIsFetching || topPilotDataIsLoading
  useEffect(() => {}, [loading])
  if (loading) {
    return (
      <div className={`card mb-5 card-rounded-bottom`}>
        {/* begin::Body */}
        <div className='card-body d-flex flex-column p-0'>
          <Skeleton
            count={1}
            height={555}
            style={{borderRadius: '0.75rem', opacity: '0.2'}}
            highlightColor={getCSSVariableValue('--bs-gray-100')}
            baseColor={getCSSVariableValue('--bs-gray-200')}
          />
        </div>
        {/* end::Body */}
      </div>
    )
  }

  if (!topPilotData?.length) {
    return <>No data found</>
  }

  // get the first 5 items

  // Highest flight count
  const highestFlightCount = topPilotData.reduce((prev:MemberData, current:MemberData) =>
    prev.flights > current.flights ? prev : current
  )

  const getFlightCountPercentage = (flightCount: number) => {
    return (flightCount / highestFlightCount.flights) * 100
  }




  const sortedPilots = topPilotData.sort((a: any, b: any) => b.flightTime - a.flightTime).slice(0,5)


  
  console.log(topPilotDataIsLoading)
  return (
    
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Top Pilots</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>By Flight Time</span>
        </h3>
        <div className='card-toolbar'>
        <ul className='nav'>
            <li className='nav-item'>
              <button
                className={'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'+ (statsButton === 'monthly'?' active':'')}
                data-bs-toggle='tab'
                disabled={topPilotDataIsLoading}
                onClick={ async (e) => {
                  e.preventDefault();
                  setStatsButton('monthly')
                  setStartDate(new Date(date.getFullYear(), date.getMonth(), 1))
                  setEndDate(new Date())
                  setTimeout(async () => {
                    await refetchTopPilotData()
                  }, 100);

                }}
              >
                Monthly
              </button>
            </li>
            <li className='nav-item'>
              <button
                className={'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'+ (statsButton === 'alltime'?' active':'')}
                data-bs-toggle='tab'
                disabled={topPilotDataIsLoading}
                onClick={ async (e) => {
                  e.preventDefault();
                  setStatsButton('alltime')
                  setStartDate('')
                  setEndDate(new Date())
                  setTimeout(async () => {
                    await refetchTopPilotData()
                  }, 100);

                }}
              >
                All Time
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
            {/* begin::Tap pane */}
            <div className='tab-pane fade show active' id='pilotstabletab1'>
            {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='w-25px'>#</th>
                      <th className='min-w-150px'>Username</th>
                      <th className='min-w-140px'>Total Time</th>
                      <th className='min-w-120px'>Flights</th>
                      {/* <th className='min-w-100px text-end'>Actions</th> */}
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    
                    {!loading && sortedPilots.map((member:MemberData, i:number) => (
                      <tr key={'roster-' + member.public_id}>
                        <td>{i + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img
                                src={
                                  member.avatar_url ||
                                  'https://rotate-group.b-cdn.net/user-images/default.jpg'
                                }
                                alt=''
                              />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href={'/profile/' + member.username}
                                className='text-dark fw-bold text-hover-primary fs-6'
                              >
                                {member.username}
                              </a>
                              {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                
                              </span> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {(member.flightTime || 0).toFixed(1)}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>Hours</span>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <span className='text-muted me-2 fs-7 fw-semibold'>
                                {member.flights}
                              </span>
                            </div>
                            <div className='progress h-6px w-100'>
                              <div
                                className='progress-bar bg-primary'
                                role='progressbar'
                                style={{width: getFlightCountPercentage(member.flights) + '%'}}
                              ></div>
                            </div>
                          </div>
                        </td>
                        {/* <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                              />
                            </a>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
              </div>
            </div>
         
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {MembersTable}
