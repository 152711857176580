import {getUsers, getUserStats} from '../../../middleware'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import {RosterTable} from '../../../_metronic/partials/widgets/tables/RosterTable'
import {FlightsTable} from '../../../_metronic/partials/widgets/tables/FlightsTable'

const cacheTime = 5 * 60000
const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LogbookPage = () => {
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
  } = useQuery('userData', getUserStats, {
    enabled: false,
    staleTime: Infinity,
    // retryDelay: 25,
  })

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <FlightsTable data={userData?.flights} className='col-xxl-12' title='My Flights' />
      </div>
    </>
  )
}

const LogbookWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Logbook'}</PageTitle>
      <LogbookPage />
    </>
  )
}

export {LogbookWrapper}
