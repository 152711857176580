import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {VerticalBidLine} from './components/VerticalBidLine'

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BidLineWrapper = () => (
  <Routes>
    <Route element={<Outlet />}>
      {/* <Route
        path='horizontal'
        element={
          <>
            <PageTitle breadcrumbs={wizardsBreadCrumbs}>Horizontal</PageTitle>
            <Horizontal />
          </>
        }
      /> */}
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={wizardsBreadCrumbs}>Bidding System</PageTitle>
            <VerticalBidLine />
          </>
        }
      />
    </Route>
  </Routes>
)

export default BidLineWrapper
