import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bold text-dark'>Your Are Done!</h2>

        <div className='text-gray-400 fw-semibold fs-6'>Bid line approved</div>
      </div>

      <div className='mb-0'>
        <div className='fs-6 text-gray-600 mb-5'>
          You have successfully confirmed your bid line. You will not be able to accept a new bid
          line until the current one is completed or removed.
        </div>

        <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6'>
          <KTSVG
            path='/media/icons/duotune/general/gen037.svg'
            className='svg-icon-2tx svg-icon-success me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-semibold'>
              <h4 className='text-gray-800 fw-bold'>Time to fly!</h4>
              <div className='fs-6 text-gray-600'>
                You can view the progress of your bid line by clicking the button below.
                <a href='/bid-lines' className='fw-bold'>
                  {' '}
                  View Bid Line
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step4}
