import {SSO_URL, CARRIER_ID, API_URL} from '../config'
// @ts-ignore
import {isJwtExpired} from 'jwt-check-expiration'

export const refresh = async (refresh?: boolean): Promise<{data: any}> => {
  const req = await fetch(`${API_URL}auth/token/new`, {
    method: 'POST',
    headers: {
      'x-access-token': localStorage.getItem('_t') || '',
    },
    // *************************************************************TODO: redirect if token does not exist**********************************************************\\
  })
  const res = await req.json()
  if (req.status === 401) {
    logout()
    window.location.reload()
  }

  localStorage.setItem('_t', res.data.access_token)
  if (refresh) {
    // reload page
    window.location.href = window.location.origin + window.location.pathname.replace('/auth', '')
  }
  return res.data
}

export const ssoLogin = (): void => {
  const currentPage = (window.location.origin + window.location.pathname)
    .replace('http://', '')
    .replace('https://', '')
    .replace('/auth', '')
  window.location.href = `${SSO_URL}auth/sso?id=${CARRIER_ID}&r=${currentPage}`
}

// Logout
export const logout = (): void => {
  localStorage.removeItem('_t')
  window.location.href = '/'
}

export const isAuthenticated = () => {
  const token = localStorage.getItem('_t')

  // check if jwt token is valid
  if (token) {
    return !isJwtExpired(token)
  }
  return false
}

export const checkLogin = () => {
  if (!isAuthenticated()) {
    ssoLogin()
  }
}

export const handleSSOResponse = (token: string): void => {
  localStorage.setItem('_t', token)
  //refresh()
  // reload page
  //window.location.href = window.location.origin + window.location.pathname
  refresh(true)
  // if (isAuthenticated()) {
  //   refresh(true)
  // } else {
  //   //ssoLogin()
  // }
}

export const SSORedirect = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get('s')
  if (token) {
    handleSSOResponse(token)
  }
}
