/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'

type Props = {
  className?: string
}
const TilesWidget4 = ({className}: Props) => {
  return (
    <div className={clsx('card h-150px', className)}>
      <div className='card-body d-flex align-items-center justify-content-between flex-wrap'>
        <div className='me-2'>
          <h2 className='fw-bold text-gray-800 mb-3'>Flight Briefing</h2>

          <div className='text-muted fw-semibold fs-6'>
            Generate a flight brief for your next flight using SimBrief.
          </div>
        </div>
        <a href='/manage' className='btn btn-primary fw-semibold'>
          Start Now
        </a>
        <a href='/schedules' className='text-muted text-decoration-underline'>
          No flights? Book now
        </a>
      </div>
    </div>
  )
}

export {TilesWidget4}
