import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Navigate, Route, Routes, Outlet, useParams, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import {FlightDetailMap} from './FlightDetailMap'
import {getAirports, getFlightById, getPositionReportsByFlightId} from '../../../middleware'

const cacheTime = 5 * 60000
const staleTime = 5 * 60000
const useQueryOptions = {cacheTime, staleTime}

export const FlightDetailWraper: React.FC = () => {
  const flightId = useLocation()
    .pathname.replace('/route', '')
    .replaceAll('/', '')
    .replaceAll('*', '')

  const {
    data: airportsData,
    isLoading: airportsDataIsLoading,
    isError: airportsDataIsError,
    refetch: refetchAirportsData,
  } = useQuery('airportsData', getAirports, {
    ...useQueryOptions,
    staleTime: Infinity,
    refetchOnMount: true,
  })

  const {
    data: flightData,
    isLoading: flightDataIsLoading,
    isError: flightDataIsError,
    refetch: refetchFlightData,
  } = useQuery('flightData', () => getFlightById(flightId), {
    ...useQueryOptions,
    staleTime: Infinity,
    refetchOnMount: true,
  })

  const {
    data: positionReports,
    isLoading: positionReportsIsLoading,
    isError: positionReportsIsError,
    refetch: refetchPositionReports,
  } = useQuery('positionReports', () => getPositionReportsByFlightId(flightId), {
    ...useQueryOptions,
    staleTime: Infinity,
    refetchOnMount: true,
  })

  const airports = useMemo(() => {
    if (airportsData?.length && flightData?.schedule.dep_icao && flightData.schedule.arr_icao) {
      const a: any = []
      airportsData.forEach((airP: any) => {
        if (flightData.schedule.dep_icao == airP.icao) a[0] = airP
        if (flightData.schedule.arr_icao == airP.icao) a[1] = airP
      })
      return a
    }
  }, [airportsData, flightData?.schedule])

  const reports = useMemo(() => {
    if (positionReports?.length) {
      const posReps: any = []
      positionReports.sort((a: any, b: any) => (a.date_time < b.date_time ? 1 : -1))

      positionReports.forEach((rep: any, i: number) => {
        posReps.push([rep.latitude, rep.longitude])
      })
      return posReps
    }
  }, [positionReports])

  const center = useMemo(() => {
    if (positionReports?.length) {
      const posMidIndx = positionReports.length / 2
      const middle = positionReports[posMidIndx.toFixed()]
      return [middle.latitude - 2, middle.longitude - 8]
    }
  }, [positionReports])

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path={flightId}
          element={
            <>
              <PageTitle>Flight Details</PageTitle>
              {/* begin::Row */}
              <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
                <div className='col-xl-12'>
                  <FlightDetailMap
                    title='Flight Reports'
                    subTitle='Crew Center'
                    flight={flightData}
                    airports={airports}
                    positionReports={reports}
                    positionReportsLoading={positionReportsIsLoading}
                    center={center}
                    className='card-xl-stretch mb-5 mb-xl-8'
                  />
                </div>
              </div>

              {/* end::Row */}
            </>
          }
        />
      </Route>
    </Routes>
  )
}
